import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Register.css';
import Swal from 'sweetalert2';
import { FaUser, FaEnvelope, FaLock, FaPhone, FaCode } from 'react-icons/fa';
import { endpoints } from '../apiConfig';
import Loader from '../components/Loader';
import qs from 'qs';
import { signOut } from 'firebase/auth';

function Register() {
  const [sponsor, setSponsor] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [agreeTnC, setAgreeTnC] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/dashboard');
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sponsorCode = params.get('referral');
    if (sponsorCode) {
      setSponsor(sponsorCode);
    }
  }, [location.search]);


  const handleRegister = async (e) => {
    e.preventDefault();

    if (!agreeTnC) {
        await Swal.fire({
            title: 'Error!',
            text: 'You must agree to the terms and conditions.',
            icon: 'error',
            confirmButtonText: 'Okay'
        });
        return;
    }

    setLoading(true);
    try {
        // Create user
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Check if UID is available
        if (!user.uid) {
            await Swal.fire({
                title: 'Error!',
                text: 'UID is not available. Registration failed.',
                icon: 'error',
                confirmButtonText: 'Okay'
            });
            return;
        }

        const registrationData = {
            uid: user.uid,
            name: name,
            email: email,
            phone: phone,
            countryCode: countryCode,
            sponsor: sponsor,
            action: 'register' // Add action parameter here
        };

        // Use qs to encode data as URL-encoded form data
        const response = await axios.post(`${endpoints.register}`, qs.stringify(registrationData), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        
        if (response.data.message === 'User registered successfully') {
            await sendEmailVerification(user);

            await Swal.fire({
                title: 'Success!',
                text: 'Successfully registered! A verification email has been sent to your email address.',
                icon: 'success',
                confirmButtonText: 'Okay'
            });

            // Log out the user
           
            navigate('/login');
        
        } else {
            await Swal.fire({
                title: 'Error!',
                text: 'Registration failed: ' + response.data.message,
                icon: 'error',
                confirmButtonText: 'Okay'
            });
        }
    } catch (error) {
        console.error('Error registering:', error.response ? error.response.data : error.message);
        await Swal.fire({
            title: 'Error!',
            text: 'An error occurred: ' + (error.response ? error.response.data.message : error.message),
            icon: 'error',
            confirmButtonText: 'Okay'
        });
    } finally {
        setLoading(false);
    }
};


//   const handleRegister = async (e) => {
//     e.preventDefault();
    
//     if (!agreeTnC) {
//         await Swal.fire({
//             title: 'Error!',
//             text: 'You must agree to the terms and conditions.',
//             icon: 'error',
//             confirmButtonText: 'Okay'
//         });
//         return;
//     }

//     setLoading(true);
//     try {
//         // Create user
//         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//         const user = userCredential.user;

//         // Check if UID is available
//         if (!user.uid) {
//             await Swal.fire({
//                 title: 'Error!',
//                 text: 'UID is not available. Registration failed.',
//                 icon: 'error',
//                 confirmButtonText: 'Okay'
//             });
//             return;
//         }

//         const registrationData = {
//             uid: user.uid,
//             name: name,
//             email: email,
//             phone: phone,
//             countryCode: countryCode,
//             sponsor: sponsor,
//             action: 'register' // Add action parameter here
//         };

//         // Use qs to encode data as URL-encoded form data
//         const response = await axios.post(`${endpoints.register}`, qs.stringify(registrationData), {
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded'
//             }
//         });
        
//         if (response.data.message === 'User registered successfully') {
//             await sendEmailVerification(user);

//             await Swal.fire({
//                 title: 'Success!',
//                 text: 'Successfully registered! A verification email has been sent to your email address.',
//                 icon: 'success',
//                 confirmButtonText: 'Okay'
//             });
//             navigate('/login');
//         } else {
//             await Swal.fire({
//                 title: 'Error!',
//                 text: 'Registration failed: ' + response.data.message,
//                 icon: 'error',
//                 confirmButtonText: 'Okay'
//             });
//         }
//     } catch (error) {
//         console.error('Error registering:', error.response ? error.response.data : error.message);
//         await Swal.fire({
//             title: 'Error!',
//             text: 'An error occurred: ' + (error.response ? error.response.data.message : error.message),
//             icon: 'error',
//             confirmButtonText: 'Okay'
//         });
//     } finally {
//         setLoading(false);
//     }
// };





  if (loading) {
    return <Loader />;
  }

  return (
    <div className="gradient-bg d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="container">
        <div className="mb-4 mt-4 text-center">
          <img 
            src={process.env.PUBLIC_URL + '/click.png'}
            alt="Logo" 
            style={{ width: '100px', marginBottom: '10px' }} 
          />
          <h2 className="text-white">Create an account</h2>
        </div>
        
        <form onSubmit={handleRegister} className="p-4 rounded shadow" style={{ background: '#444' }}>
          <div className="mb-3">
            <label className="form-label text-white">Referral Code</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaCode />
              </span>
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Code"
                value={sponsor}
                onChange={(e) => setSponsor(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Full Name</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaUser />
              </span>
              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Email</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaEnvelope />
              </span>
              <input
                type="email"
                className="form-control custom-placeholder"
                placeholder="your-email@xyz.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Phone Number</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaPhone />
              </span>
              <select
  className="form-select custom-placeholder"
  value={countryCode}
  style={{ background: '#333', color: 'white', borderColor: '#333' }}
  onChange={(e) => setCountryCode(e.target.value)}
>
  <option value="+1">+1 (USA)</option>
  <option value="+44">+44 (UK)</option>
  <option value="+61">+61 (Australia)</option>
  <option value="+91">+91 (India)</option>
  <option value="+81">+81 (Japan)</option>
  <option value="+49">+49 (Germany)</option>
  <option value="+33">+33 (France)</option>
  <option value="+39">+39 (Italy)</option>
  <option value="+55">+55 (Brazil)</option>
  <option value="+34">+34 (Spain)</option>
  <option value="+7">+7 (Russia)</option>
  <option value="+86">+86 (China)</option>
  <option value="+27">+27 (South Africa)</option>
  <option value="+82">+82 (South Korea)</option>
  <option value="+64">+64 (New Zealand)</option>
  <option value="+52">+52 (Mexico)</option>
  <option value="+31">+31 (Netherlands)</option>
  <option value="+46">+46 (Sweden)</option>
  <option value="+41">+41 (Switzerland)</option>
  <option value="+48">+48 (Poland)</option>
  <option value="+34">+34 (Spain)</option>
  <option value="+47">+47 (Norway)</option>
  <option value="+32">+32 (Belgium)</option>
  <option value="+30">+30 (Greece)</option>
  <option value="+90">+90 (Turkey)</option>
  <option value="+971">+971 (UAE)</option>
  <option value="+966">+966 (Saudi Arabia)</option>
  <option value="+353">+353 (Ireland)</option>
  <option value="+45">+45 (Denmark)</option>
  <option value="+351">+351 (Portugal)</option>
  <option value="+60">+60 (Malaysia)</option>
  <option value="+62">+62 (Indonesia)</option>
  <option value="+65">+65 (Singapore)</option>
  <option value="+66">+66 (Thailand)</option>
  <option value="+98">+98 (Iran)</option>
  <option value="+63">+63 (Philippines)</option>
  <option value="+94">+94 (Sri Lanka)</option>
  <option value="+254">+254 (Kenya)</option>
  <option value="+256">+256 (Uganda)</option>
  <option value="+233">+233 (Ghana)</option>
  <option value="+234">+234 (Nigeria)</option>
  <option value="+92">+92 (Pakistan)</option>
</select>

              <input
                type="text"
                className="form-control custom-placeholder"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label text-white">Password</label>
            <div className="input-group">
              <span className="input-group-text" style={{ background: '#333', color: 'white', border: 'none' }}>
                <FaLock />
              </span>
              <input
                type="password"
                className="form-control custom-placeholder"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ background: '#333', color: 'white', borderColor: '#333' }}
              />
            </div>
          </div>

          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="tnc"
              checked={agreeTnC}
              onChange={(e) => setAgreeTnC(e.target.checked)}
            />
            <label className="form-check-label text-white" htmlFor="tnc">
              I agree to the Terms and Conditions
            </label>
          </div>

          <button type="submit" className="btn btn-primary w-100">
            Register
          </button>

          <div className="text-center mt-4" style={{ marginBottom: '40px' }}>
            <a href="/forgot-password" className="text-white me-3">Forgot Password?</a>
            <span className="text-white">|</span>
            <a href="/login" className="text-white ms-3">Login</a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
