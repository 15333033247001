import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaUsers, FaChevronRight } from 'react-icons/fa'; // Importing icons from react-icons
import { GiTwoCoins } from 'react-icons/gi';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { endpoints } from '../apiConfig';
import Loader from '../components/Loader';
import { GiBattleGear } from "react-icons/gi";

function Earn() {
 
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [latestTasks, setLatestTasks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setUser(user);
        try {
          const response = await axios.get(`${endpoints.getTasks}/${user.uid}`);
          setLatestTasks(response.data);
          console.log('Fetched data:', response.data);
        } catch (error) {
          // Log detailed error information
          console.error('Error fetching data:', error.message);
          if (error.response) {
            // The request was made and the server responded with a status code
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.error('Request data:', error.request);
          } else {
            // Something happened in setting up the request
            console.error('Error:', error.message);
          }
        }
      } else {
        navigate('/login');
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  const handleNavigate = (path) => {
    navigate(path);
  };

  const permanentItems = [
    { id: 1, title: 'Subscribe YouTube Channel', subtitle: '+75,000', imageUrl: '/youtube.png', subImageUrl: '/gold.png', link: 'https://www.youtube.com/@parahash' },
    { id: 2, title: 'Follow our X account', subtitle: '+25,000', imageUrl: '/x.png', subImageUrl: '/gold.png', link: 'https://x.com/para_hash' },
    { id: 3, title: 'Follow on Instagram', subtitle: '+35,000', imageUrl: '/instagram.png', subImageUrl: '/gold.png', link: 'https://www.instagram.com/parahashgaming/' },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="gradient-bg d-flex flex-column justify-content-between vh-100">
      <div className="container-fluid gradient-bg">
        <h3 
          className="text-center mb-4 mt-2" 
          style={{
            fontFamily: "'Montserrat', sans-serif", 
            fontWeight: '800',
            padding: '15px 25px',
            borderRadius: '12px',
            textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)', 
            letterSpacing: '4px', 
            fontSize: '28px', 
            lineHeight: '1.1', 
            margin: '0 auto', 
            width: 'fit-content', 
          }}
        >
          <span style={{ color: '#ecf2f8' }}>Para</span>
          <span style={{ color: '#ecf2f8' }}>Hash</span>
        </h3>

        <div className="row mb-1">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + '/gold.png'} 
              alt="User Icon"
              style={{ height: '100px', marginRight: '1px', marginTop: '10px' }} 
            />
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-12 d-flex justify-content-center">
            <h3 className="text-center text-white mb-4 mt-2">Earn More Coins</h3>
          </div>
        </div>

        <div className="row no-gutters border-top-gold rounded-top w-100 mx-0 shadow-top justify-content-between mb-3">
          <div className="col-12">
            <h6 className="text-light mb-2 mt-3">Latest Tasks</h6>
            {latestTasks.map((task) => (
              <div
                key={task.id}
                className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2"
                style={{ borderRadius: '10px' }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={process.env.PUBLIC_URL + task.image} 
                    alt={task.title}
                    style={{ height: '30px', marginRight: '10px' }} 
                  />
                  <div>
                    <span className="text-white">{task.title.charAt(0).toUpperCase() + task.title.slice(1)}</span>
                    <div className="d-flex align-items-center">
                      <img
                        src={process.env.PUBLIC_URL + 'gold.png'} 
                        alt={task.description}
                        style={{ height: '15px', marginRight: '5px' }} 
                      />
                      <span className="text-light" style={{ fontSize: '12px' }}>{task.description.charAt(0).toUpperCase() + task.description.slice(1)}</span>
                    </div>
                  </div>
                </div>
                <a href={task.link} target="_blank" rel="noopener noreferrer">
                  <FaChevronRight className="text-light" />
                </a>
              </div>
            ))}

            <h6 className="text-light mb-2 mt-3">Tasks List</h6>
            {permanentItems.map((item) => (
              <div
                key={item.id}
                className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2"
                style={{ borderRadius: '10px' }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={process.env.PUBLIC_URL + item.imageUrl} 
                    alt={item.title}
                    style={{ height: '30px', marginRight: '10px' }} 
                  />
                  <div>
                    <span className="text-white">{item.title}</span>
                    <div className="d-flex align-items-center">
                      <img
                        src={process.env.PUBLIC_URL + item.subImageUrl} 
                        alt={item.subtitle}
                        style={{ height: '15px', marginRight: '5px' }} 
                      />
                      <span className="text-light" style={{ fontSize: '12px' }}>{item.subtitle}</span>
                    </div>
                  </div>
                </div>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                <FaChevronRight className="text-light" />
                </a>
              </div>
            ))}





            {/* <h6 className="text-light mb-2 mt-3">Tasks List</h6> */}
           
              <div
                
                className="d-flex justify-content-between align-items-center p-2 bg-dark mb-2"
                style={{ borderRadius: '10px' }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={process.env.PUBLIC_URL + '/referrals.png'} 
                    alt='title'
                    style={{ height: '30px', marginRight: '10px' }} 
                  />
                  <div>
                    <span className="text-white">Invite Referrals</span>
                    <div className="d-flex align-items-center">
                      <img
                        src={process.env.PUBLIC_URL + '/gold.png'} 
                        alt='sub title'
                        style={{ height: '15px', marginRight: '5px' }} 
                      />
                      <span className="text-light" style={{ fontSize: '12px' }}>+25,000 / Referral</span>
                    </div>
                  </div>
                </div>
                <a href='/referrals' rel="noopener noreferrer">
                <FaChevronRight className="text-light" />
                </a>
              </div>
          











          </div>
        </div>
      </div>

      <div className="sticky-bottom d-flex justify-content-around align-items-center bg-dark p-2" style={{ zIndex: 1000 }}>
        <div className="text-center" onClick={() => handleNavigate('/dashboard')}>
          <FaHome style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Home</p> 
        </div>
        <div className="text-center" onClick={() => handleNavigate('/earn')}>
          <GiTwoCoins style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Earn</p> 
        </div>

        <div className="text-center" onClick={() => handleNavigate('/battlefield')}>
          <GiBattleGear style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Battlefield</p> 
        </div>


        <div className="text-center" onClick={() => handleNavigate('/referrals')}>
          <FaUsers style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Referrals</p> 
        </div>
        <div className="text-center" onClick={() => handleNavigate('/profile')}>
          <FaUser style={{ color: '#ffbf00' }} size={16} /> 
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Profile</p> 
        </div>
      </div>







    </div>
  );
}

export default Earn;
