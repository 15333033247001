// // // //apiConfig.js
// const API_BASE_URL = 'http://localhost/parahash';
// const API_BASE_URL_FRONTEND = 'http://localhost:3000';


// export const endpoints = {
//   io: `${API_BASE_URL}`, 
//   login: `${API_BASE_URL}/`,
//   register: `${API_BASE_URL}/api/index.php/api/register`,
//   getUserData: `${API_BASE_URL}/api/index.php/api/getUserData`,
//   updateUserData: `${API_BASE_URL}/api/index.php/api/update_profile`,
//   getTokens: `${API_BASE_URL}/api/index.php/api/getUserTokens`,
//   referralCount: `${API_BASE_URL}/api/index.php/api/getReferralCount`,
//   referrals: `${API_BASE_URL}/api/index.php/api/get_referrals`,
//   getTasks: `${API_BASE_URL}/api/index.php/api/get_tasks`,
//   updateTokens: `${API_BASE_URL}/api/index.php/api/update_tokens`,
 
//   refercode: `${API_BASE_URL_FRONTEND}/register?referral=`,
//   // Add more endpoints here
// };





const API_BASE_URL = 'https://api.theblocktech.com';
const API_BASE_URL_FRONTEND = 'https://parahash.com';


export const endpoints = {
  io: `${API_BASE_URL}`, 
  login: `${API_BASE_URL}/`,
  register: `${API_BASE_URL}/api/index.php/api/register`,
  getUserData: `${API_BASE_URL}/api/index.php/api/getUserData`,
  updateUserData: `${API_BASE_URL}/api/index.php/api/update_profile`,
  getTokens: `${API_BASE_URL}/api/index.php/api/getUserTokens`,
  referralCount: `${API_BASE_URL}/api/index.php/api/getReferralCount`,
  referrals: `${API_BASE_URL}/api/index.php/api/get_referrals`,
  getTasks: `${API_BASE_URL}/api/index.php/api/get_tasks`,
  updateTokens: `${API_BASE_URL}/api/index.php/api/update_tokens`,
 
  refercode: `${API_BASE_URL_FRONTEND}/register?referral=`,
  // Add more endpoints here
};



