import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import Swal from 'sweetalert2';
import { endpoints } from '../apiConfig';
import { FaHome,FaUser, FaUsers} from 'react-icons/fa'; // Importing icons from react-icons
import { GiTwoCoins } from "react-icons/gi";

function UpdateProfile() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({ name: '', phone: '', wallet: '' });

    const handleEarn = async () => {
    
        navigate('/earn');
      };
    
      const handleReferrals = async () => {
        
        navigate('/referrals');
      };
    
      const handleProfile = async () => {
        
        navigate('/profile');
      };
    
      const handleHome = async () => {
        
        navigate('/dashboard');
      };

      



    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    
                    const response = await axios.get(`${endpoints.getUserData}/${user.uid}`);
                    setUserData({
                        name: response.data.name || '',
                        phone: response.data.phone || '',
                        wallet: response.data.wallet || '',
                    });
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                navigate('/login');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                console.log(`${endpoints.updateUserData}/${user.uid}`, userData);
                await axios.post(`${endpoints.updateUserData}/${user.uid}`, userData);
                Swal.fire({
                    title: 'Success!',
                    text: 'Your profile has been updated.',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                });
                navigate('/profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred.';
            Swal.fire({
                title: 'Error!',
                text: `Failed to update your profile: ${errorMessage}`,
                icon: 'error',
                confirmButtonText: 'Okay',
            });
        }
    };

    return (
        <div className="gradient-bg d-flex flex-column justify-content-between vh-100">
            <div className="container-fluid gradient-bg">
                <h3 className="text-center mb-4 mt-2" style={{
                    fontFamily: "'Montserrat', sans-serif",
                    fontWeight: '800',
                    padding: '15px 25px',
                    borderRadius: '12px',
                    textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
                    letterSpacing: '4px',
                    fontSize: '28px',
                    lineHeight: '1.1',
                    margin: '0 auto',
                    width: 'fit-content',
                }}>
                    <span style={{ color: '#ecf2f8' }}>Para</span>
                    <span style={{ color: '#ecf2f8' }}>Hash</span>
                </h3>

                <div className="row mb-1">
                    <div className="col-12 d-flex justify-content-center">
                        <img
                            src={process.env.PUBLIC_URL + '/profile.png'}
                            alt="User Icon"
                            style={{ height: '50px', marginRight: '1px', marginTop: '10px' }}
                        />
                    </div>
                </div>

                <h5 className="text-center text-white mb-4">Update Profile</h5>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label className="text-white">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={userData.name}
                            onChange={handleChange}
                            style={{ background: '#333', color: 'white', borderColor: '#333' }}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-white">Phone</label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone"
                            value={userData.phone}
                            onChange={handleChange}
                            style={{ background: '#333', color: 'white', borderColor: '#333' }}
                            required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-white">Wallet Address</label>
                        <input
                            type="text"
                            className="form-control"
                            name="wallet"
                            value={userData.wallet}
                            onChange={handleChange}
                            style={{ background: '#333', color: 'white', borderColor: '#333' }}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Update Profile</button>
                </form>
            </div>






            <div className="sticky-bottom d-flex justify-content-around align-items-center bg-dark p-2" style={{ zIndex: 1000 }}>
  <div className="text-center" onClick={handleHome}>
    <FaHome style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Home</p> {/* Reduced font size */}
  </div>
  <div className="text-center" onClick={handleEarn}>
    <GiTwoCoins style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Earn</p> {/* Reduced font size */}
  </div>
  <div className="text-center" onClick={handleReferrals}>
    <FaUsers style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Referrals</p> {/* Reduced font size */}
  </div>
  <div className="text-center" onClick={handleProfile}>
    <FaUser style={{ color: '#ffbf00' }} size={16} /> {/* Reduced icon size */}
    <p className="text-white mb-0" style={{ fontSize: '12px' }}>Profile</p> {/* Reduced font size */}
  </div>
</div>






        </div>
    );
}

export default UpdateProfile;
