import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaUsers } from 'react-icons/fa';
import { GiTwoCoins } from "react-icons/gi";
import io from 'socket.io-client';
import { endpoints } from '../apiConfig';
import { sendEmailVerification } from 'firebase/auth';
import Swal from 'sweetalert2';
import Loader from '../components/Loader';
import '../styles/Register.css';
import { GiBattleGear } from "react-icons/gi";
import qs from 'qs';



function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [tokens, setTokens] = useState(0);
  const [level, setLevel] = useState(0);
  const [levelid, setLevelid] = useState(0);
  const [referralCount, setReferralCount] = useState(0);
  const [nextLevelTarget, setNextLevelTarget] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [user, setUser] = useState(null);

  const percentage = (((levelid - 1) / 10) * 100).toFixed(2);
  const formatNumber = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1) + 'B';
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + 'k';
    }
    return num;
  };

  // const handleTokenUpdate = () => {
  //   if (userData) {
  //     socket.emit('updateTokens', userData.uid);
  //   }
  // };


  const handleTokenUpdate = async () => {
    if (userData) {
        try {
          console.log(user.uid);
            // Send AJAX request to the CodeIgniter PHP endpoint
            const data = {
              uid: user.uid,
               };

            const updateResponse = await axios.post(`${endpoints.updateTokens}`, qs.stringify(data), {
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
          });
      
            if (updateResponse.data.status === 'success') {
                // Fetch updated tokens
                const tokens = updateResponse.data.tokens;
                setTokens(tokens); // Update state with new tokens
            } else {
                console.error('Error:', updateResponse.data.message);
            }
        } catch (error) {
            console.error('Error updating tokens:', error);
        }
    }
};


  // const handleTokenUpdate = async () => {
  //   if (userData) {
  //     try {
  //       // Send AJAX request to the CodeIgniter PHP endpoint
  //       await axios.post(`${endpoints.updateTokens}`, {
  //         uid: userData.id, // Assuming userData contains the user ID
  //       });
    
  //       // Fetch updated tokens
  //       const responseTokens = await axios.get(`${endpoints.getTokens}/${user.uid}`);
  //       setTokens(responseTokens.data.tokens);
       
  //     } catch (error) {
  //       console.error('Error updating tokens:', error);
  //     }
  //   }
  // };

  // const handleClick = () => {
  //   setIsClicked(true);
  //   handleTokenUpdate().then(() => {
  //     setIsClicked(false);
  //   });
  // };
  

  const handleClick = () => {
    setIsClicked(true);
    handleTokenUpdate();
    setTimeout(() => setIsClicked(false), 500);
  };

  const handleResendVerification = async () => {
    if (user) {
      await sendEmailVerification(user);
      await Swal.fire({
        title: 'Success!',
        text: 'A verification email has been resent to your email address.',
        icon: 'success',
        confirmButtonText: 'Okay'
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setUser(user);
        
        setIsEmailVerified(user.emailVerified);
        try {

          if(user){
          const response = await axios.get(`${endpoints.getUserData}/${user.uid}`);
          if(response.data){
          setUserData(response.data);
          }
          const responseTokens = await axios.get(`${endpoints.getTokens}/${user.uid}`);
          console.log(responseTokens.data.level);
          setTokens(responseTokens.data.tokens);
          setLevel(responseTokens.data.level_name);
          setLevelid(responseTokens.data.level);
         
          setNextLevelTarget(responseTokens.data.target);

          const referralCode = response.data.referral_code;
          const responseReferral = await axios.get(`${endpoints.referralCount}`, {
            params: { uid: user.uid, referral_code: referralCode }
          });
          setReferralCount(responseReferral.data.count);
        }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);




  const handleEarn = () => {
    navigate('/earn');
  };

  const handleBattlefield = () => {
    navigate('/battlefield');
  };

  const handleReferrals = () => {
    navigate('/referrals');
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleHome = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="gradient-bg d-flex flex-column justify-content-between">
      <div className="container-fluid">

        <h3 className="text-center mb-4 mt-2" style={styles.header}>
          <span style={{ color: '#ecf2f8' }}>Para</span>
          <span style={{ color: '#ecf2f8' }}>Hash</span>
        </h3>

        <div className="row">
          <div className="col-6 d-flex justify-content-start flex-column align-items-start">
            <div className="d-flex align-items-center">
              <img src={process.env.PUBLIC_URL + '/level.png'} alt="User Icon" style={styles.icon} />
              <span className="text-white" style={styles.level}>{level ? level : 'Loading...'}</span>
            </div>
            <p className="text-white" style={styles.label}>Current Level</p>
          </div>

          <div className="col-6 d-flex justify-content-start flex-column align-items-end">
            <div className="d-flex align-items-center">
              <span className="text-white" style={styles.level}>{userData ? userData.referral_code : 'Loading...'}</span>
            </div>
            <p className="text-white" style={styles.label}>Referral Code</p>
          </div>
        </div>

        <div className="row no-gutters border-top-gold rounded-top w-100 mx-0 shadow-top justify-content-between mb-3">
          <div className="col-4 text-center border-right">
            <div className="p-1 bg-dark text-info mb-0" style={styles.card}>
              <span>Per Click</span>
              <div className="d-flex align-items-center justify-content-center mt-1">
                <img src={process.env.PUBLIC_URL + '/gold.png'} alt="Claim Per Hour" style={styles.smallIcon} />
                <span>+100</span>
              </div>
            </div>
          </div>

          <div className="col-4 text-center border-right">
            <div className="p-1 bg-dark text-info mb-0" style={styles.card}>
              <span>Next Level</span>
              <div className="d-flex align-items-center justify-content-center mt-1">
                <img src={process.env.PUBLIC_URL + '/click.png'} alt="Next Level" style={styles.smallIcon} />
                <span>{nextLevelTarget ? formatNumber(nextLevelTarget) : 'Loading...'}</span>
              </div>
            </div>
          </div>

          <div className="col-4 text-center">
            <div className="p-1 bg-dark text-info mb-0" style={styles.card}>
              <span>Referrals</span>
              <div className="d-flex align-items-center justify-content-center mt-1">
                <FaUsers size={20} style={{ color: '#ffbf00', marginRight: '6px' }} />
                <span>{referralCount ? referralCount : 0}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row no-gutters w-100 mx-0 justify-content-center mb-3">
          <div className="col-12 text-center">
            <div className="p-4 bg-dark rounded shadow-lg" style={styles.totalCoinsContainer}>
              <div className="d-flex align-items-center justify-content-center mb-2">
                <img src={process.env.PUBLIC_URL + '/blue.png'} alt="HASH TOKEN" style={styles.tokenIcon} />
                <h3 className="text-white mb-0" style={styles.totalCoins}>
                  {tokens !== undefined && tokens !== null ? `${tokens.toLocaleString()}` : 0}
                </h3>
              </div>
              <p className="text-white" style={styles.totalLabel}>Total Coins</p>
            </div>
          </div>
        </div>

        <div className="row no-gutters w-100 mx-0 justify-content-between mb-4">
          <div className="col-12 justify-content-center">
            <label className="text-white mb-2" style={styles.progressLabel}>Level Progress</label>
            <div className="progress" style={styles.progressContainer}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animate"
                role="progressbar"
                style={{
                  width: `${percentage}%`,
                  borderRadius: '6px',
                  background: 'linear-gradient(to right, #8f56ee, #ffb74d, #23a8fe)'
                }}
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>

        <div className="row no-gutters w-100 mx-0 justify-content-center mb-3 mt-4">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={process.env.PUBLIC_URL + '/click.png'}
              alt="Clickable"
              className={`img-fluid shadow ${isClicked ? 'animate-click' : ''}`}
              style={{ height: '200px' }}
              onClick={handleClick}
            />
          </div>
        </div>

        {user && !isEmailVerified && (
          <div className="alert alert-warning d-flex align-items-center" style={styles.alert}>
            <p className="mb-0 me-2" style={{ flex: 1 }}>
              Your email address is not verified. Please check your inbox.
            </p>
            <button className="btn btn-sm btn-secondary" onClick={handleResendVerification}>
              Resend
            </button>
          </div>
        )}
      </div>

      <div className="sticky-bottom d-flex justify-content-around align-items-center bg-dark p-2" style={{ zIndex: 1000 }}>
        <div className="text-center" onClick={handleHome}>
          <FaHome style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Home</p>
        </div>
        <div className="text-center" onClick={handleEarn}>
          <GiTwoCoins style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Earn</p>
        </div>

        <div className="text-center" onClick={handleBattlefield}>
          <GiBattleGear style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Battlefield</p>
        </div>


        <div className="text-center" onClick={handleReferrals}>
          <FaUsers style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Referrals</p>
        </div>
        <div className="text-center" onClick={handleProfile}>
          <FaUser style={{ color: '#ffbf00' }} size={16} />
          <p className="text-white mb-0" style={{ fontSize: '12px' }}>Profile</p>
        </div>
      </div>





    </div>
  );
}

// Styles for better readability
const styles = {
  header: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '800',
    padding: '15px 25px',
    borderRadius: '12px',
    textShadow: '4px 4px 12px rgba(0, 0, 0, 0.7)',
    letterSpacing: '4px',
    fontSize: '28px',
    lineHeight: '1.1',
    margin: '0 auto',
    width: 'fit-content',
  },
  icon: {
    height: '30px',
    marginRight: '8px'
  },
  level: {
    fontSize: '15px',
  },
  label: {
    marginTop: '4px',
    fontSize: '12px',
  },
  card: {
    fontSize: '12px',
    borderRadius: '5px',
  },
  smallIcon: {
    height: '20px',
    marginRight: '5px',
  },
  totalCoinsContainer: {
    maxWidth: '100%',
    margin: '0 auto',
  },
  tokenIcon: {
    width: '40px',
    marginRight: '8px',
  },
  totalCoins: {
    fontWeight: 'bold',
  },
  totalLabel: {
    fontSize: '14px',
    fontStyle: 'italic',
  },
  progressLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white',
  },
  progressContainer: {
    height: '12px',
    borderRadius: '6px',
    backgroundColor: '#343a40',
  },
  alert: {
    padding: '10px',
    marginBottom: '10px',
  },
};

export default Dashboard;
